import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import Observer from 'react-intersection-observer';
import isStaticRender from '../../../lib/isStaticRender';

const background = css`
  background-color: ${props => props.bgColor};
`;
const Bg = styled.div`
  background-color: ${props => props.bg};
  ${props => props.inView ? background : 'background-color: transparent'};
  transition: background-color 0.5s ease-in-out;
`;

export default class SectionBackground extends PureComponent {
  constructor() {
    super();

    this.state = {
      inView: isStaticRender
    }
  }

  handleVisibility(inView) {
    if (isStaticRender) {
      return;
    }
    this.setState({ inView });
  }

  render() {
    const { inView } = this.state;
    const { children, bgColor } = this.props;

    return (
      <Observer triggerOnce threshold={0.2} onChange={this.handleVisibility.bind(this)}>
        <Bg inView={inView} ref={ref => { this.bgRef = ref; }} bg={bgColor}>
          {children}
        </Bg>
      </Observer>
    )
  }
}

SectionBackground.propTypes = {
  children: PropTypes.node.isRequired,
  bgColor: PropTypes.string.isRequired
}