import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import theme from '../../../../lib/theme';

export default function Container({ ratio, isHero, ...props }) {
  const newRatio = isHero ? 0 : ratio;
  const StyledContainer = styled.div`
    position: relative;
    cursor: pointer;
    overflow: hidden;
    height: ${isHero ? '100vh' : 0};
    padding-bottom: ${newRatio};

    @media screen and (max-width: ${theme.breakpoints.s}) {
      height: ${isHero ? 'calc(100vh - 40px)' : 0};
      padding-bottom: ${isHero ? '0' : ratio};
    }
  `;

  return (
    <StyledContainer {...props} />
  )
}

Container.propTypes = {
  ratio: PropTypes.string,
  isHero: PropTypes.bool
}

Container.defaultProps = {
  ratio: '56.25%',
  isHero: false
}

/*
  Ratios:

  100% = 1:1
  56.25% = 16:9
  177% = 9:16
  75% = 4:3
  133% = 3:4
*/