import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import theme from '../../../lib/theme';

export default function LoadingBubble() {
  const opacity = keyframes`
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  `;
  const StyledLoadingBubble = styled.div`
    position: absolute;
    top: 8px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 40px;
    border-radius: 30px;
    border: 3px solid ${theme.colors.black};
    background-color: ${theme.colors.white};

    @media screen and (max-width: ${theme.breakpoints.s}) {
      border: 2px solid ${theme.colors.black};
      left: -20px;
    }
  `;
  const Circle = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${theme.colors.darkBlue};
    opacity: 0.5;
    margin: 0 3px;
    animation: ${opacity} 0.75s infinite;

    &:nth-child(2) {
      animation-delay: 0.25s;
    }

    &:last-child {
      animation-delay: 0.5s;
    }
  `;

  return (
    <StyledLoadingBubble>
      <Circle />
      <Circle />
      <Circle />
    </StyledLoadingBubble>
  )
}