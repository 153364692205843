import React, { PureComponent } from 'react';
import Observer from 'react-intersection-observer';
import styled, { css } from 'styled-components/macro';
import PropTypes from 'prop-types';
import isStaticRender from '../../../lib/isStaticRender';

const translate = css`
  transform: translateY(${props => props.translateY})
`;
const Container = styled.div`
  position: relative;
  transition-duration: ${props => props.duration}ms;
  transition-delay: ${props => props.delay}ms;
  opacity: ${props => props.visible ? 1 : 0};
  ${props => props.visible ? 'transform:  translateY(0)' : translate}
`;

export default class Fade extends PureComponent {
  constructor() {
    super();

    this.state = {
      // When it's a static render we need the block to be
      // visible instead of waiting for it to be in view
      inView: isStaticRender
    }

    this.handleVisibility = this.handleVisibility.bind(this);
  }

  handleVisibility(inView) {
    if (isStaticRender) {
      return;
    }

    this.setState({
      inView
    });
  }

  render() {
    const { inView } = this.state;
    const { children, threshold, delay, duration, triggerOnce, translateY } = this.props;

    return (
      <Observer
        triggerOnce={triggerOnce}
        threshold={threshold}
        onChange={this.handleVisibility}
      >
        <Container
          translateY={translateY}
          delay={delay}
          duration={duration}
          visible={inView}
        >
          {children}
        </Container>
      </Observer>
    )
  }
}

Fade.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  threshold: PropTypes.number,
  duration: PropTypes.number,
  triggerOnce: PropTypes.bool,
  translateY: PropTypes.string
}

Fade.defaultProps = {
  delay: 0,
  threshold: 0.1,
  duration: 300,
  triggerOnce: true,
  translateY: '50px'
}