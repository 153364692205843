import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Observer from 'react-intersection-observer';

import leftArrow from '../../../../assets/images/left-arrow-icon.svg';
import rightArrow from '../../../../assets/images/right-arrow-icon.svg';
import theme from '../../../../lib/theme';

const duration = 400;
const easing = "cubic-bezier(0.770, 0.000, 0.175, 1.000)";

const Img = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -9px 0 0 -10px;
  width: 21px;
  height: 16px;  

  @media screen and (max-width: ${theme.breakpoints.s}) {
    margin: -7px 0 0 -7px;
    width: 14px;
    height: 11px;
  }
`;

const Clone = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(${props => !props.isNext ? '100%' : '-100%'});
  padding: 15px 15px 14px 15px;
`;
const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const Container = styled.div`
  overflow: hidden;
  position: relative;
  width: 56px;
  cursor: pointer;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    width: 40px;
  }

  .path {
    fill: none;
    stroke: #252525;
    stroke-width: 2;
    transition: 0.8s cubic-bezier(0.645, 0.045, 0.355, 1.000);
    transition-delay: ${props => props.isNext ? '0.7s' : '0.5s'}

    stroke-dasharray: 200;
    stroke-dashoffset: 200;
  }

  &.draw .path {
    stroke-dashoffset: 0;
  }

  &:hover ${Clone} {
    transform: translateX(0);
    opacity: 1;
    transition: ${duration}ms ${easing};
  }

  &:hover ${Inner} {
    transform: translateX(${props => !props.isNext ? '-200%' : '200%'});
    opacity: 0;
    transition: ${duration}ms ${easing};
  }
`;

export default class NavButton extends PureComponent {
  handleVisibility(inView) {
    if (inView) {
      this.containerRef.classList += ' draw';
    }
  }

  render() {
    const { isNext } = this.props;

    return (
      <Observer onChange={this.handleVisibility.bind(this)} threshold={0.2} triggerOnce>
        <Container ref={ref => { this.containerRef = ref; }} isNext={isNext}>
          <Clone isNext={isNext}>
            <Img src={isNext ? rightArrow : leftArrow} alt={isNext ? 'Next' : 'Previous'} />
          </Clone>
          <Inner isNext={isNext}>
            <Img src={isNext ? rightArrow : leftArrow} alt={isNext ? 'Next' : 'Previous'} />
          </Inner>
          {isNext ? (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 51">
              <path className="path" d="M5,1C2.8,1,1,2.8,1,5v41c0,2.2,1.8,4,4,4h46c2.2,0,4-1.8,4-4V5c0-2.2-1.8-4-4-4H5z" />
            </svg>
          ) : (
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 51">
                <path className="path" d="M51,1c2.2,0,4,1.8,4,4v41c0,2.2-1.8,4-4,4H5c-2.2,0-4-1.8-4-4V5c0-2.2,1.8-4,4-4H51z" />
              </svg>
            )}
        </Container>
      </Observer>
    )
  }
}

NavButton.propTypes = {
  isNext: PropTypes.bool
}

NavButton.defaultProp = {
  isNext: false
}