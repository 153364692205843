import styled from "styled-components/macro";
import theme from "../../../lib/theme";

import content from "../../../content/sustainability.json";

const i1 = content.rightImage.image;
const image1 = require(`../../../assets/images/${i1}`);

export const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 60px;

  @media screen and (max-width: ${theme.breakpoints.m}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: ${theme.breakpoints.s}) {
    padding-top: 45px;
    padding-bottom: 40px;
  }
`;

export const Flex = styled.div`
  display: flex;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    display: block;
  }
`;

export const LeftContainer = styled.div`
  width: 33.33%;
  padding-right: 30px;
  align-self: center;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 20px;
    text-align: center;
    h2 {
      text-align: center;
    }
  }
`;

export const RightContainer = styled.div`
  width: 66.66%;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    width: 100%;
  }
`;

export const RightImageContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 110%;
  overflow: hidden;
`;

export const ImageRight = styled.div`
  transition: transform 0.4s ease-in-out;
  transform-origin: center;
  position: absolute;
  background-image: url(${image1});
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
`;
