import React from "react";

import theme from "../../../lib/theme";

import Text from "../../common/Text";
import Spacing from "../../common/Spacing";
import PageWrap from "../../common/PageWrap";
import Fade from "../../common/Fade";
import SectionBackground from "../../common/SectionBackground";
import MaxWidth from "../../common/MaxWidth";

import content from "../../../content/sustainability.json";

import {
  Container,
  Flex,
  LeftContainer,
  RightContainer,
  RightImageContainer,
  ImageRight
} from "./style.js";

export default function Sustainability() {
  return (
    <SectionBackground bgColor={theme.colors.lightGreen}>
      <Container>
        <PageWrap>
          <Fade threshold={0.3} duration={500}>
            <Text
              size="xxxxl"
              weight="500"
              align="center"
              copy={content.title}
            />
            <Spacing size="40px" position="t">
              <MaxWidth value={600} center>
                <Text
                  size="xs"
                  font="serif"
                  align="center"
                  lineHeight={1.3}
                  copy={content.subtitle}
                />
              </MaxWidth>
            </Spacing>
          </Fade>
          <Spacing size="70px" position="t">
            <Flex>
              <LeftContainer>
                <Fade threshold={0.3} duration={500}>
                  <Text
                    size="xs"
                    font="sans"
                    lineHeight={1.3}
                    copy={content.leftText.title}
                  />
                  <Text
                    size="xs"
                    font="serif"
                    lineHeight={1.3}
                    copy={content.leftText.copy}
                  />
                  {/* {this link below has to be activated as soon as we have the definitive url} */}
                  {/* <Spacing size="20px" position="t">
                    <Text
                      as="a"
                      target="_blank"
                      href="https://www.aceandtate.com/about/our-product"
                      size="xxxxs"
                      isUnderlined
                      weight="500"
                      copy={content.learnmore}
                    />
                  </Spacing> */}
                  <Spacing size="50px" position="t" />
                </Fade>
              </LeftContainer>
              <RightContainer>
                <Fade delay={100} threshold={0.3} duration={500}>
                  <RightImageContainer>
                    <ImageRight />
                  </RightImageContainer>
                </Fade>
              </RightContainer>
            </Flex>
          </Spacing>
        </PageWrap>
      </Container>
    </SectionBackground>
  );
}
