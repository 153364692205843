import React from 'react';
import styled from 'styled-components/macro';

import Text from '../Text';
import Spacing from '../Spacing';

import theme from '../../../lib/theme';

export default function Avatar({ image, name }) {
  const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  `;
  const StyledAvatar = styled.div`
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-image: url(${image});
    background-size: cover;
    background-position: center;

    @media screen and (max-width: ${theme.breakpoints.m}) {
      width: 47px;
      height: 47px;
    }
  `;

  const nameObject = {
    en: name,
    de: name,
    nl: name
  }

  return (
    <Container>
      <StyledAvatar />
      {name && (
        <Spacing size="10px" position="t">
          <Text as="div" size="xxs" align="center" weight="800" copy={nameObject} /> 
        </Spacing>
      )}
    </Container>
  )
}