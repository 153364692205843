import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';
import throttle from 'lodash/throttle';

import lerp from '../../../../lib/lerp';
import Raf from '../../../../lib/Raf';
import theme from '../../../../lib/theme';

import circle from '../../../../assets/images/vision-statement-circle2.png';
import amp from '../../../../assets/images/vision-statement-amp2.png';

const Container = styled.div`
  position: relative;
  width: 175px;
  height: 175px;
  margin: 0 auto;

  @media screen and (max-width: ${theme.breakpoints.m}) {
    width: 120px;
    height: 120px;
  }
`;
const StyledCircle = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const Amp = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export default class Circle extends PureComponent {
  constructor() {
    super();

    this.RAF = Raf;
    this.RAFID = null;

    this.lastScroll = 0;
    this.targetRotate = 0;
    this.rotate = 0;
    this.easing = 0.04;
    this.speed = 0.2;

    this.animateLoop = this.animateLoop.bind(this);
    this.handleScroll = throttle(this.handleScroll.bind(this), 30);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });

    this.RAFID = this.RAF.subscribe(this.animateLoop);
    this.RAF.start();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

    this.RAF.unsubscribe(this.RAFID);
  }

  handleScroll(e) {
    const y = e.pageY || window.pageYOffset;
    const diff = y - this.lastScroll;

    this.targetRotate += (diff * this.speed);
    this.lastScroll = y;
  }

  animateLoop() {
    this.rotate = lerp(this.rotate, this.targetRotate, this.easing);
    this.circleRef.style.transform = `rotate(${-this.rotate}deg)`;
  }

  render() {
    return (
      <Container ref={ref => { this.containerRef = ref; }}>
        <StyledCircle
          ref={ref => { this.circleRef = ref; }}
          src={circle}
          alt="Vision Statement"
        />
        <Amp src={amp} alt="Vision Statement" />
      </Container>
    )
  }
}