const theme = {
  breakpoints: {
    m: "1024px",
    s: "750px"
  },
  fonts: {
    sans: "AmperSans, helvetica, sans-serif",
    serif: "RLUUTYReckless, serif",
    graphik: "Graphik Wide, sans-serif",
    druk: "Druk Wide, sans-serif"
  },
  fontSizes: {
    // desktop, m, s
    xxxxxs: ["18px", "14px", "11px"],
    xxxxs: ["18px", "18px", "18px"],
    xxxs: ["19px", "19px", "14px"],
    xxs: ["22px", "19px", "14px"],
    xs: ["24px", "23px", "23px"],
    s: ["27px", "24px", "13px"],
    m: ["29px", "23px", "21px"],
    l: ["30px", "24px", "24px"],
    ll: ["30px", "24px", "18px"],
    xl: ["36px", "26px", "21px"],
    xxl: ["47px", "32px", "22px"],
    xxxl: ["55px", "36px", "23px"],
    xxxxl: ["62px", "44px", "32px"],
    xxxxxl: ["142px", "90px", "54px"],
    hero: ["60px", "40px", "30px"]
  },
  colors: {
    black: "#040303",
    white: "#ffffff",
    // red: '#e53416',
    red: "#CC3433",
    pink: "#FFE4E3",
    yellow: "#FECB36",
    blue: "#cbecf3",
    darkBlue: "#0F2E8B",
    beige: "#e4d3ba",
    darkRed: "#6B1A22",
    lightGreen: "#EAFAF3"
  }
};

export default theme;
