import React from 'react';
import styled from 'styled-components/macro';

import PageWrap from '../../common/PageWrap';
import Text from '../../common/Text';
import Spacing from '../../common/Spacing';
import MaxWidth from '../../common/MaxWidth';
import Fade from '../../common/Fade';

import theme from '../../../lib/theme';
import Tooltip from '../../common/Tooltip';
import SectionBackground from '../../common/SectionBackground';

import content from '../../../content/materials.json';

const i1 = content.leftImage.image;
const i2 = content.rightImage.image;
const image1 = require(`../../../assets/images/${i1}`);
const image2 = require(`../../../assets/images/${i2}`);

export default function Materials() {
  const Container = styled.div`
    padding-top: 80px;
    padding-bottom: 100px;

    @media screen and (max-width: ${theme.breakpoints.m}) {
      padding-top: 80px;
      padding-bottom: 90px;
    }

    @media screen and (max-width: ${theme.breakpoints.s}) {
      padding-top: 60px;
      padding-bottom: 90px;
    }
  `;
  const Flex = styled.div`
    display: flex;

    @media screen and (max-width: ${theme.breakpoints.s}) {
      display: block;
    }
  `;
  const LeftContainer = styled.div`
    width: 66.66%;
    padding-right: 30px;

    @media screen and (max-width: ${theme.breakpoints.s}) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px;
    }
  `;
  const RightContainer = styled.div`
    width: 33.33%;

    @media screen and (max-width: ${theme.breakpoints.s}) {
      width: 100%;
    }
  `;
  const LeftImageContainer = styled.div`
    position: relative;
    height: 0;
    padding-bottom: 110%;
    overflow: hidden;
  `;
  const RightImageContainer = styled.div`
    position: relative;
    height: 0;
    padding-bottom: 150%;
    overflow: hidden;
  `;
  const ImageLeft = styled.div`
    transition: transform 0.4s ease-in-out;
    transform-origin: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${image1});
    background-size: cover;
    background-position: center;
  `;
  const ImageRight = styled.div`
    transition: transform 0.4s ease-in-out;
    transform-origin: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${image2});
    background-size: cover;
    background-position: center;
  `;
  const TooltipHover = styled.div`
    &:hover ~ ${ImageLeft},
    &:hover ~ ${ImageRight} {
      transform: scale(1.05);
    }
  `;

  return (
    <SectionBackground bgColor={theme.colors.white}>
      <Container>
        <PageWrap>
          <Fade threshold={0.3} duration={500}>
            <Text
              size="xxxxl"
              weight="500"
              align="center"
              copy={content.title}
            />
            <Spacing size="40px" position="t">
              <MaxWidth value={780} center>
                <Text
                  size="xs"
                  font="serif"
                  align="center"
                  lineHeight={1.3}
                  copy={content.subtitle}
                />
              </MaxWidth>
            </Spacing>
          </Fade>
          <Spacing size="70px" position="t">
            <Flex>
              <LeftContainer>
                <Fade threshold={0.3} duration={500}>
                  <LeftImageContainer>
                    {content.leftImage.tooltips.map((item, index) => (
                      <TooltipHover key={index}>
                        <Tooltip {...item} />
                      </TooltipHover>
                    ))}
                    <ImageLeft />
                  </LeftImageContainer>
                </Fade>
              </LeftContainer>
              <RightContainer>
                <Fade delay={100} threshold={0.3} duration={500}>
                  <RightImageContainer>
                    {content.rightImage.tooltips.map((item, index) => (
                      <TooltipHover key={index}>
                        <Tooltip {...item} />
                      </TooltipHover>
                    ))}
                    <ImageRight />
                  </RightImageContainer>
                  <Spacing size="20px" position="t">
                    <Text
                      as="a"
                      target="_blank"
                      href="https://www.aceandtate.com/about/our-product"
                      size="xxxxs"
                      isUnderlined
                      weight="500"
                      copy={content.learnmore}
                    />
                  </Spacing>
                </Fade>
              </RightContainer>
            </Flex>
          </Spacing>
        </PageWrap>
      </Container>
    </SectionBackground>
  )
}