import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import throttle from 'lodash/throttle';

import Text from '../../../common/Text';
import Spacing from '../../../common/Spacing';
import Video from '../../../common/Video';

import lerp from '../../../../lib/lerp';
import Raf from '../../../../lib/Raf';
import theme from '../../../../lib/theme';

const MasterContainer = styled.div`
  overflow: hidden;
  cursor: move;
  cursor: grab;
`;

const Container = styled.div`
  position: relative;
  display: flex;
`;

const QuoteContainer = styled.div`
  display: flex;
  position: relative;
  margin: 0 50px 0 50px;
`;
const QuoteInner = styled.div`
    position: relative;
    display: inline-flex;
    align-items: flex-end;
    padding-top: 100px;

    @media screen and (max-width: ${theme.breakpoints.s}) {
      padding-top: 50px;
    }
  `;
const StyledQuote = styled.div`
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    background-color: ${props => props.bgColor};
    width: 600px;
    padding: 40px;
    padding-bottom: ${props => props.paddingBottom};

    @media screen and (max-width: ${theme.breakpoints.s}) {
      width: 350px;
    }

    &:hover {
      z-index: 10;
    }
  `;
const StyledVideo = styled.div`
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    width: 540px;
    position: absolute;
    bottom: ${props => props.isEven ? 'auto' : 0};
    top: ${props => props.isEven ? 0 : 'auto'};
    right: ${props => props.isEven ? quoteXMap[0] : 'auto'};
    left: ${props => props.isEven ? 'auto' : quoteXMap[0]};

    @media screen and (max-width: ${theme.breakpoints.s}) {
      width: 300px;
      right: ${props => props.isEven ? quoteXMap[1] : 'auto'};
      left: ${props => props.isEven ? 'auto' : quoteXMap[1]};
    }

    &:hover {
      z-index: 10;
    }
  `;
const Flex = styled.div`
    display: flex;
  `;

const xMap = ['200px', '100px'];
const quoteXMap = ['-220px', '-100px'];

export default class MovingQuotes extends PureComponent {
  constructor() {
    super();

    this.quoteRef = [];
    
    this.animationContainerRef = [];
    this.quoteLastX = [];
    this.quoteTargetX = [];
    this.quoteX = [];
    this.quoteFactor = [];

    this.styledVideoRef = [];
    this.styledQuoteRef = [];

    this.RAF = Raf;
    this.RAFID = null;

    this.lastX = 0;
    this.targetX = 0;
    this.x = 0;

    this.dragEasing = 0.1;
    this.boundEasing = 0.05;
    this.easing = this.dragEasing;

    this.isMouseDown = false;

    this.animateLoop = this.animateLoop.bind(this);
    this.handleMouseMove = throttle(this.handleMouseMove.bind(this), 50);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleScroll = throttle(this.handleScroll.bind(this), 50);
  }

  componentDidMount() {
    this.containerW = this.containerRef.offsetWidth;
    this.masterContainerW = this.masterContainerRef.offsetWidth;

    this.masterContainerRef.addEventListener('mouseup', this.handleMouseUp);
    this.masterContainerRef.addEventListener('touchend', this.handleMouseUp);
    
    this.masterContainerRef.addEventListener('mousedown', this.handleMouseDown);
    this.masterContainerRef.addEventListener('touchstart', this.handleMouseDown, { passive: true });
    
    this.masterContainerRef.addEventListener('mousemove', this.handleMouseMove);
    this.masterContainerRef.addEventListener('touchmove', this.handleMouseMove, { passive: true });

    window.addEventListener('scroll', this.handleScroll, { passive: true });

    this.animationContainerRef.forEach((item, index) => {
      this.quoteLastX[index] = 0;
      this.quoteTargetX[index] = 0;
      this.quoteX[index] = 0;
      this.quoteFactor[index] = 7 * (index + 1);
    });

    this.positionQuotes();

    this.RAFID = this.RAF.subscribe(this.animateLoop);
    this.RAF.start();
  }

  componentWillUnmount() {
    this.masterContainerRef.removeEventListener('mousemove', this.handleMouseMove);
    this.masterContainerRef.removeEventListener('touchmove', this.handleMouseMove);
    this.masterContainerRef.removeEventListener('mousedown', this.handleMouseDown);
    this.masterContainerRef.removeEventListener('touchstart', this.handleMouseDown);
    this.masterContainerRef.removeEventListener('mouseup', this.handleMouseUp);
    this.masterContainerRef.removeEventListener('touchend', this.handleMouseUp);
    window.removeEventListener('scroll', this.handleScroll);

    this.RAF.unsubscribe(this.RAFID);
  }

  positionQuotes() {
    if (window.innerWidth <= 1000) {
      let totalWidth = 0;

      this.animationContainerRef.forEach((item, index) => {
        if (index < 2) {
          const width = item.offsetWidth;
          const newWidth = totalWidth + width;

          totalWidth = newWidth;
        }
      });

      const center = totalWidth / 1.7;

      this.lastX = -center;
      this.targetX = -center;
      this.x = -center;
    }
  }

  handleMouseDown(e) {
    const isTouch = e.targetTouches && e.targetTouches.length > 0;
    
    if (!isTouch) e.preventDefault();

    this.lastY = e.clientY;
    this.lastX = e.clientX;
    this.isMouseDown = true;
    this.easing = this.dragEasing;
  }

  handleMouseUp() {
    this.isMouseDown = false;

    this.checkBounds();
  }

  handleMouseMove(e) {
    if (!this.isMouseDown) return;
    const touchX = e.targetTouches && e.targetTouches.length > 0 && e.targetTouches[0].pageX;
    const factor = touchX ? 2 : 1.4; // speed up drag on mobile
    const x = e.clientX || touchX;
    const diff = (x - this.lastX) * factor;

    if (Math.abs(diff) >= 10 && touchX) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.targetX += diff || 0;
    this.lastX = x;
  }

  checkBounds() {
    const leftBound = 0;
    let rightBound = 0;
    const x = this.x * -1;

    this.animationContainerRef.forEach((item, index) => {
      if (index < this.animationContainerRef.length) {
        const width = item.offsetWidth;
        const newWidth = rightBound + width;

        rightBound = newWidth;
      }
    });
    const newRightBound = rightBound - window.innerWidth

    if (x < leftBound) {
      this.easing = this.boundEasing;
      this.targetX = leftBound;
    } else if (x > newRightBound) {
      this.easing = this.boundEasing;
      this.targetX = -newRightBound;
    }
  }

  handleScroll() {
    const wH = window.innerHeight;

    this.animationContainerRef.forEach((item, index) => {
      const itemBottom = item.getBoundingClientRect().bottom;
      const offsetBottom = itemBottom - wH;
      const diff = offsetBottom - this.quoteLastX[index];

      if (offsetBottom >= 0 && offsetBottom <= wH) {
        this.quoteTargetX[index] += diff || 0;
        this.quoteLastX[index] = offsetBottom;
      } else if (offsetBottom <= 0) {
        this.quoteTargetX[index] = -1;
        this.quoteLastX[index] = -1;
      }
    })
  }

  animateLoop() {
    this.x = lerp(this.x, this.targetX, this.easing);
    this.containerRef.style.transform = `translateX(${this.x}px) rotate(0.0001deg)`; // rotate = sub-pixel aliase fix

    this.animationContainerRef.forEach((item, index) => {
      this.quoteX[index] = lerp(this.quoteX[index], this.quoteTargetX[index], this.easing);
      const x = this.quoteX[index] / this.quoteFactor[index];
      this.styledQuoteRef[index].style.transform = `translate(${x / 0.65}px, 0) rotate(0.0001deg)`;
      this.styledVideoRef[index].style.transform = `translate(${x / 0.9}px, 0) rotate(0.0001deg)`;
    })
  }
 
  render() {
    const { quotes } = this.props;
    const padding = window.innerWidth <= 600 ? xMap[1] : xMap[0];

    return (
      <MasterContainer ref={ref => { this.masterContainerRef = ref; }}>
        <Container ref={ref => { this.containerRef = ref; }}>
          {quotes.map((item, index) => {
            const posterNode = require(`../../../../assets/images/${item.poster}`);
            const isEven = index % 2 === 0;
            const style = {
              paddingLeft: isEven ? 0 : padding,
              paddingRight: isEven ? padding : 0
            }

            return (
              <Flex key={item.id} ref={ref => { this.animationContainerRef[index] = ref; }}>
                <QuoteContainer
                  ref={ref => { this.quoteRef[index] = ref; }}
                  style={style}
                >
                  <QuoteInner>
                    <StyledQuote ref={ref => { this.styledQuoteRef[index] = ref; }} bgColor={item.bgColor} paddingBottom={item.paddingBottom}>
                      <Text
                        size="xxl"
                        color={item.color}
                        font="serif"
                        copy={item.copy}
                      />
                      <Spacing size="25px" position="t">
                        <Flex>
                          <Text as="span" weight="500" size="s" color={item.color} copy={item.author} />
                          {item.role && (
                            <Text as="span" weight="normal" size="s" color={item.color} copy={item.role} />
                          )}
                        </Flex>
                      </Spacing>
                    </StyledQuote>
                    <StyledVideo ref={ref => { this.styledVideoRef[index] = ref; }} isEven={isEven}>
                      <Video
                        poster={posterNode}
                        bgVideo={item.trailer}
                        bgVideoMobile={item.trailerMobile}
                        src={item.src}
                        srcDe={item.srcDe}
                        ratio={item.ratio}
                      />
                    </StyledVideo>
                  </QuoteInner>
                </QuoteContainer>
              </Flex>
            )
          })}
        </Container>
      </MasterContainer>
    )
  }
}

MovingQuotes.propTypes = {
  quotes: PropTypes.array.isRequired
}