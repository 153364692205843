import React from 'react';
import styled from 'styled-components/macro';

import theme from '../../../lib/theme';

export default function PageWrap({ children, noOverflow }) {
  const StyledPageWrap = styled.div`
    padding: 0 50px;
    overflow: ${noOverflow ? 'initial' : 'hidden'};

    @media screen and (max-width: ${theme.breakpoints.m}) {
      padding: 0 30px;
    }

    @media screen and (max-width: ${theme.breakpoints.s}) {
      padding: 0 25px;
    }
  `;

  return (
    <StyledPageWrap>{children}</StyledPageWrap>
  )
}