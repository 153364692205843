import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';
import throttle from 'lodash/throttle';

import logo from '../../../assets/images/header-logo.svg';
import logoBlack from '../../../assets/images/header-logo-b.svg';
import arrow from '../../../assets/images/left-arrow-icon.svg';
import arrowYellow from '../../../assets/images/left-arrow-icon-y.svg';
import arrowWhite from '../../../assets/images/left-arrow-icon-w.svg';
import Text from '../Text';
import theme from '../../../lib/theme';

const arrowMap = {
  white: arrowWhite,
  yellow: arrowYellow,
  black: arrow
}

const Container = styled.header`
  position: fixed;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  transition: transform 0.3s ease-in-out;
`;

const Logo = styled.img`
  width: 149px;
  height: 24px;
`;

const Back = styled.a`
  position: absolute;
  left: 20px;
  top: 26px;
  display: flex;
  align-items: center;
`;

const Arrow = styled.img`
  margin-right: 15px;
  width: 16px;
`;

const TextContainer = styled.div`
  @media screen and (max-width: ${theme.breakpoints.s}) {
    display: none;
  }
`;

export default class Header extends PureComponent {
  constructor() {
    super();

    this.state = {
      isBlack: false,
      videoPlayed: false,
      backColor: 'yellow'
    }

    this.prevScroll = 0;
    this.scroll = 0;

    this.handleScroll = throttle(this.handleScroll.bind(this), 50);
    this.handleVideoPlay = this.handleVideoPlay.bind(this);
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('videoPlay', this.handleVideoPlay);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('videoPlay', this.handleVideoPlay);
  }

  hideContainer() {
    this.containerRef.style.transform = 'translateY(-100%)';
  }

  showContainer() {
    this.containerRef.style.transform = 'translateY(0)';
  }

  handleVideoPlay(e) {
    const isHero = e.isHero;

    if (!isHero) return;

    this.hideContainer();
    this.setState({
      videoPlayed: true
    });
  }

  handleScroll(e) {
    this.scroll = e.pageY || window.pageYOffset;

    const diff = this.scroll - this.prevScroll;

    if (diff >= 10) {
      this.hideContainer();
    } else if (diff < 0) {
      this.showContainer();
    }

    if (this.scroll >= window.innerHeight - 65) {
      this.setState({
        isBlack: true,
        backColor: 'black'
      });
    } else {
      const { videoPlayed } = this.state;
      this.setState({
        isBlack: false,
        backColor: videoPlayed ? 'white' : 'yellow'
      });
    }

    this.prevScroll = this.scroll;
  }

  render() {
    const { isBlack, backColor } = this.state;
    const backCopy = {
      en: 'Back to Shop',
      de: 'Back to Shop',
      nl: 'Back to Shop',
    }

    return (
      <Container ref={ref => { this.containerRef = ref; }}>
        <Back href="https://aceandtate.com">
          <Arrow src={arrowMap[backColor]} alt="" />
          <TextContainer>
            <Text size="xxxxs" color={backColor} copy={backCopy} weight="500" />
          </TextContainer>
        </Back>
        <a href="https://aceandtate.com">
          <Logo src={isBlack ? logoBlack : logo} alt="" />
        </a>
      </Container>
    )
  }
}