import React, { PureComponent, Fragment } from 'react';
import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import styled, { keyframes, css } from 'styled-components/macro';

import Text from '../Text';
import Spacing from '../Spacing';
import MaxWidth from '../MaxWidth';

import NavButton from './NavButton';
import theme from '../../../lib/theme';

const transitionDuration = 400;
const arrivingEasing = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)';
const leavingEasing = 'cubic-bezier(0.550, 0.055, 0.675, 0.190)';

const leavingLeft = keyframes`
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(-10%); }
`;
const leavingRight = keyframes`
  0% { opacity: 1; transform: translateX(0); }
  100% { opacity: 0; transform: translateX(10%); }
`;
const arrivingLeft = keyframes`
  0% { opacity: 0; transform: translateX(-10%); }
  100% { opacity: 1; transform: translateX(0); }
`;
const arrivingRight = keyframes`
  0% { opacity: 0; transform: translateX(10%); }
  100% { opacity: 1; transform: translateX(0); }
`;

const leaving = css`
  animation: ${props => props.direction === 'prev' ? leavingRight : leavingLeft} ${transitionDuration}ms ${leavingEasing} 1 both !important;
`;
const arriving = css`
  animation: ${props => props.direction === 'prev' ? arrivingLeft : arrivingRight} ${transitionDuration}ms ${arrivingEasing} 1 both;
`;

const Container = styled.div`
  position: relative;
`;
const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    margin-top: 30px;
  }
`;

const ItemContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const TitleContainer = styled.div`
  opacity: 0;
  ${props => props.leaving ? leaving : ''}
  ${props => props.active ? arriving : ''}
`;

const CopyContainer = styled.div`
  opacity: 0;
  ${props => props.leaving ? leaving : ''}
  ${props => props.active ? arriving : ''}
  animation-delay: 0.02s !important;
`;

const ItemInner = styled.div`
  position: relative;
`;

const Inner = styled.div`
`;

export default class Carousel extends PureComponent {
  constructor() {
    super();

    this.state = {
      currentIndex: 0,
      prevIndex: 0,
      isLeaving: false
    }

    this.itemInnerRef = [];
    this.copyContainerRef = [];

    this.triggerPrev = debounce(this.triggerPrev.bind(this), 200, { leading: true, trailing: false });
    this.triggerNext = debounce(this.triggerNext.bind(this), 200, { leading: true, trailing: false });
    this.handleResize = throttle(this.setHeight.bind(this), 100);
    this.setHeight = this.setHeight.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    setTimeout(() => {
      this.handleResize()
    }, 100)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  setHeight() {
    const { currentIndex } = this.state;
    if (!this.itemInnerRef[currentIndex]) return;
    const height = this.itemInnerRef[currentIndex].offsetHeight;
    const copyHeight = this.copyContainerRef[currentIndex].offsetHeight;
    const newHeight = height + copyHeight + 80;

    this.innerRef.style.minHeight = `${newHeight}px`;
  }

  triggerPrev() {
    const { currentIndex } = this.state;
    const { items } = this.props;
    const nextIndex = currentIndex === 0 ? items.length - 1 : currentIndex - 1;

    this.direction = 'prev';

    this.setState({
      isLeaving: true,
      prevIndex: currentIndex
    });

    setTimeout(() => {
      this.setState({
        isLeaving: false,
        currentIndex: nextIndex
      }, () => {
        this.direction = null;
      });
    }, transitionDuration * 1.2);

    setTimeout(() => {
      this.setHeight();
    }, transitionDuration * 2)
  }

  triggerNext() {
    const { currentIndex } = this.state;
    const { items } = this.props;
    const nextIndex = currentIndex === items.length - 1 ? 0 : currentIndex + 1;

    this.direction = 'next';

    this.setState({
      isLeaving: true,
      prevIndex: currentIndex
    });

    setTimeout(() => {
      this.setState({
        isLeaving: false,
        currentIndex: nextIndex
      }, () => {
        this.direction = null;
      });
    }, transitionDuration * 1.2);

    setTimeout(() => {
      this.setHeight();
    }, transitionDuration * 2)
  }

  render() {
    const { currentIndex, isLeaving, prevIndex } = this.state;
    const { items } = this.props;
    const navObject = {
      en: `${currentIndex + 1}/${items.length}`,
      de: `${currentIndex + 1}/${items.length}`,
      nl: `${currentIndex + 1}/${items.length}`
    }

    return (
      <Fragment>
        <Container ref={ref => { this.containerRef = ref; }}>
          <Inner ref={ref => { this.innerRef = ref; }}>
            {items.map((item, index) => {
              const isActive = index === currentIndex;
              const isPrev = index === prevIndex;
              const leaving = isLeaving && isPrev;
              const hidden = !isActive && !isLeaving;
              const direction = this.direction;

              return (
                <ItemContainer key={item.id}>
                  <ItemInner>
                    <TitleContainer
                      direction={direction}
                      leaving={leaving}
                      active={isActive}
                      hidden={hidden}
                    >
                      <MaxWidth value={1050} center>
                        <div ref={ref => { this.itemInnerRef[index] = ref; }}>
                          <Text
                            size="xxxxxl"
                            align="center"
                            font="graphik"
                            lineHeight={1.1}
                            copy={item.title}
                            style={{ hyphens: 'auto' }}
                          />
                        </div>
                      </MaxWidth>
                    </TitleContainer>
                    <CopyContainer
                      direction={direction}
                      leaving={leaving}
                      active={isActive}
                      hidden={hidden}
                      ref={ref => { this.copyContainerRef[index] = ref; }}
                    >
                      <Spacing size={["80px", "65px", "50px"]} position="t">
                        <MaxWidth value={670} center>
                          <Text as="p" size="ll" align="center" copy={item.content} />
                        </MaxWidth>
                      </Spacing>
                    </CopyContainer>
                  </ItemInner>
                </ItemContainer>
              )
            })}
          </Inner>
          <NavContainer hidden>
            <div onClick={this.triggerPrev}>
              <NavButton />
            </div>
            <Spacing size="34px" position="x">
              <Text as="p" size="l" copy={navObject} />
            </Spacing>
            <div onClick={this.triggerNext}>
              <NavButton isNext />
            </div>
          </NavContainer>
        </Container>
      </Fragment>
    )
  }
}