import React from 'react';
import styled from 'styled-components/macro';

import theme from '../../../lib/theme';

import Text from '../../common/Text';
import Spacing from '../../common/Spacing';
import Carousel from '../../common/Carousel';
import PageWrap from '../../common/PageWrap';
import Fade from '../../common/Fade';
import SectionBackground from '../../common/SectionBackground';

import content from '../../../content/principles.json';

export default function Principles() {
  const Container = styled.div`
    padding-top: 100px;
    padding-bottom: 60px;

    @media screen and (max-width: ${theme.breakpoints.m}) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @media screen and (max-width: ${theme.breakpoints.s}) {
      padding-top: 45px;
      padding-bottom: 40px;
    }
  `;

  return (
    <SectionBackground bgColor={theme.colors.white}>
      <Container>
        <PageWrap>
          <Fade threshold={0.3} duration={500}>
            <Text size="l" weight="500" align="center" copy={content.title} />
          </Fade>
          <Fade threshold={0.3} duration={500} delay={100}>
            <Spacing size={["60px", "50px", "50px"]} position="y" type="padding">
              <Carousel items={content.items} />
            </Spacing>
          </Fade>
        </PageWrap>
      </Container>
    </SectionBackground>
  )
}