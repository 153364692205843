import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

import theme from '../../../lib/theme';

import tickWhite from '../../../assets/images/bubble-tick-white.png';
import tickWhiteLeft from '../../../assets/images/bubble-tick-white-left.png';
import tickL from '../../../assets/images/bubble-l-tick.png';
import tickLLeft from '../../../assets/images/bubble-l-tick-left.png';
import tickYellow from '../../../assets/images/bubble-s-tick-yellow.png';
import tickYellowLeft from '../../../assets/images/bubble-s-tick-yellow-left.png';

import Text from '../Text';
import MaxWidth from '../MaxWidth';
import Spacing from '../Spacing';

const textSizeMap = {
  xxs: 'xxxxs',
  xs: 'xxxxs',
  s: 'm',
  l: 'xl'
}

const marginMap = {
  xxs: ['25px', '25px'],
  xs: ['25px', '25px'],
  s: ['35px', '25px'],
  l: ['35px', '25px']
}

const paddingMap = {
  xxs: ['10px 40px', '7px 15px'],
  xs: ['10px 40px', '7px 40px'],
  s: ['18px 30px', '14px 15px'],
  l: ['30px 55px 55px 55px', '20px 20px']
}

const tickMap = {
  xxs: {
    l: tickWhiteLeft,
    r: tickWhite
  },
  xs: {
    l: tickWhiteLeft,
    r: tickWhite
  },
  s: {
    l: tickWhiteLeft,
    r: tickWhite,
    yellowL: tickYellowLeft,
    yellowR: tickYellow
  },
  l: {
    l: tickLLeft,
    r: tickL
  },
}

const tickSizeMap = {
  xs: {
    x: 7,
    y: 15
  },
  xxs: {
    x: 7,
    y: 15
  },
  s: {
    x: 7,
    y: 15
  },
  l: {
    x: 12,
    y: 22
  }
}

const borderSizeMap = {
  xxs: 2,
  xs: 2,
  s: 2,
  l: 3
}

const borderRadiusMap = {
  xxs: '5px',
  xs: '10px',
  s: '10px',
  l: '10px',
}

const alignMap = {
  xxs: 'center',
  xs: 'center',
  s: 'center',
  l: 'left'
}

export default function Bubble({
  size,
  date,
  isLeft,
  hasNoArrow,
  bgColor,
  copy,
  pointer,
  ...props
}) {
  const padding = paddingMap[size];
  const margin = marginMap[size];
  const leftTick = bgColor === 'yellow' ? tickMap[size].yellowL : tickMap[size].l;
  const rightTick = bgColor === 'yellow' ? tickMap[size].yellowR : tickMap[size].r;
  const tick = isLeft ? leftTick : rightTick;
  const StyledBubble = styled.div`
    position: relative;
    display: inline-block;
    cursor: ${pointer ? 'pointer' : 'inherit'};
    border: ${borderSizeMap[size]}px solid ${theme.colors.black};
    border-radius: ${borderRadiusMap[size]};
    background-color: ${theme.colors[bgColor]};
    padding: ${padding[0]};
    width: 100%;
    max-width: ${size === 's' ? '355px' : 'auto'};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

    @media screen and (max-width: ${theme.breakpoints.s}) {
      padding: ${padding[1]};
    }

    &::after {
      content: "";
      display: ${hasNoArrow ? 'none' : 'inline'}
      position: absolute;
      width: ${tickSizeMap[size].x}px;
      height: ${tickSizeMap[size].y}px;
      background-image: url(${tick});
      background-size: ${tickSizeMap[size].x}px ${tickSizeMap[size].y}px;
      right: ${!isLeft ? `-${tickSizeMap[size].x}px` : 'auto'};
      left: ${isLeft ? `-${tickSizeMap[size].x}px` : 'auto'};
      top: ${size === 'l' ? '24px' : '50%'};
      transform: ${size === 'l' ? 'translateY(0)' : 'translateY(-50%)'};
    }

    @media screen and (max-width: ${theme.breakpoints.s}) {
      border: 2px solid ${theme.colors.black};

      &::after {
        width: ${size === 'l' ? `${tickSizeMap.s.x}px` : `${tickSizeMap[size].x}px`};
        height: ${size === 'l' ? `${tickSizeMap.s.y}px` : `${tickSizeMap[size].y}px`};
        background-size: ${size === 'l' ?
          `${tickSizeMap.s.x}px ${tickSizeMap.s.y}px`
          :
          `${tickSizeMap[size].x}px ${tickSizeMap[size].y}px`
        };
        right: ${!isLeft ? `-${tickSizeMap.s.x}px` : 'auto'};
        left: ${isLeft ? `-${tickSizeMap.s.x}px` : 'auto'};
      }
    }
  `;
  const Container = styled.div`
    position: relative;
    display: block;
    margin-left: ${isLeft ? margin[0] : '0'};
    margin-right: ${isLeft ? '0' : margin[0]};

    @media screen and (max-width: ${theme.breakpoints.s}) {
      margin-left: ${isLeft ? margin[1] : '0'};
      margin-right: ${isLeft ? '0' : margin[1]};
    }
  `;

  const dateObject = {
    en: date,
    de: date,
    nl: date
  }

  return (
    <Container {...props}>
      <MaxWidth value={750} center>
        <StyledBubble>
          <Text
            size={textSizeMap[size]}
            align={alignMap[size]}
            as="div"
            lineHeight={size === 'l' ? 1.33 : 1}
            copy={copy}
            style={{ width: '100%' }}
          />
        </StyledBubble>
        {date && (
          <Spacing size="10px" position="t">
            <Text size="xxxxxs" as="p" copy={dateObject} />
          </Spacing>
        )}
      </MaxWidth>
    </Container>
  )
}

Bubble.propTypes = {
  copy: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['xxs', 'xs', 's' , 'l']),
  date: PropTypes.string,
  isLeft: PropTypes.bool,
  hasNoArrow: PropTypes.bool,
  bgColor: PropTypes.oneOf(Object.keys(theme.colors))
}

Bubble.defaultProps = {
  size: 's',
  date: null,
  isLeft: false,
  hasNoArrow: false,
  bgColor: 'white'
}