import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';
import Observer from 'react-intersection-observer';
import format from 'date-fns/format';
import { Link } from 'react-scroll'
import isStaticRender from '../../../../lib/isStaticRender';
import Avatar from '../../../common/Avatar';
import Bubble from '../../../common/Bubble';
import Spacing from '../../../common/Spacing';
import LoadingBubble from '../../../common/LoadingBubble';

import aliceImage from '../../../../assets/images/alice.png';
import youImage from '../../../../assets/images/you.png';

import content from '../../../../content/join.json';
import theme from '../../../../lib/theme';

const Container = styled.div`
  position: relative;
`;

const Flex = styled.div`
  display: flex;
`;

const Answers = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  transition: opacity 0.3s;

  opacity: ${props => props.visible ? 1 : 0};
`;

const AliceContainer = styled.div`
  position: relative;
`;

const AliceLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 40px;
  transition: 0.2s ease-in-out;

  opacity: ${props => props.visible ? 1 : 0};
  transform: ${props => props.visible ? 'translateX(0)' : 'translateX(-10px)'};
`;

const AliceBubble = styled.div`
  transition: 0.25s 0.3s ease-in-out;

  opacity: ${props => props.visible ? 1 : 0};
  transform: ${props => props.visible ? 'translateX(0)' : 'translateX(15px)'};

  @media screen and (max-width: ${theme.breakpoints.s}) {
    transform: ${props => props.visible ? 'translateX(0)' : 'translateX(10px)'};
  }
`;

const AnswerContainer = styled.div`
  transition: 0.25s 0.4s ease-in-out;

  &:nth-child(2) {
    transition-delay: 0.5s;
  }

  &:nth-child(3) {
    transition-delay: 0.6s;
  }

  opacity: ${props => props.visible ? 1 : 0};
  transform: ${props => props.visible ? 'translateX(0)' : 'translateX(-10px)'};
`;

const Width = styled.div`
  @media screen and (max-width: ${theme.breakpoints.s}) {
    width: 100%;
  }
`;

const LastAvatar = styled.div`
  transition: 0.4s;
  opacity: 0;
`;
const LastBubble = styled.div`
  transition: 0.4s 0.3s;
  opacity: 0;
  transform: translateX(15px);
`;

export default class ChatBot extends PureComponent {
  constructor() {
    super();

    this.date = '';

    this.delays = {
      state1: 1000,
      state2: 2000
    }

    // state 0 = loading
    // state 1 = Alice text
    // state 2 = answers
    // state 3 = lastCTA
    this.state = {
      chatState: isStaticRender ? Infinity : 0,
      lastStateContent: {
        url: '',
        copy: {
          en: '',
          de: '',
          nl: '',
        }
      }
    }
  }

  launchChatBot() {
    this.date = format(new Date(), 'H:mm');

    if (this.state.chatState >= 1) return;

    setTimeout(() => {
      this.setState({ chatState: 1 });
    }, this.delays.state1);

    setTimeout(() => {
      this.setState({ chatState: 2 });
    }, this.delays.state2);
  }

  handleClick(cta) {
    this.setState({
      lastStateContent: content.chatBot[cta],
      chatState: 3
    });

    setTimeout(() => {
      this.lastAvatarRef.style.opacity = 1;
      this.lastBubbleRef.style.opacity = 1;
      this.lastBubbleRef.style.transform = 'translateX(0)';
    }, 1)

    // setTimeout(() => {
    //   if (cta === 'cta2') {
    //     window.location = content.chatBot[cta].url
    //   }
    // }, 2000)
  }

  handleIntersection(inView) {
    if (inView) {
      this.launchChatBot();
    }
  }

  render() {
    const { chatState, lastStateContent } = this.state;

    return (
      <Observer threshold={0.1} onChange={this.handleIntersection.bind(this)}>
        <Container ref={ref => { this.containerRef = ref; }}>
          <Flex>
            <Avatar image={aliceImage} name="Ace & Tate" />
            <AliceContainer>
              <AliceBubble visible={chatState >= 1}>
                <Bubble
                  size="l"
                  date={this.date}
                  isLeft
                  isLoading={chatState >= 1 ? false : true}
                  copy={content.chatBot.alice}
                />
              </AliceBubble>
              <AliceLoading visible={chatState === 0}>
                <LoadingBubble />
              </AliceLoading>
            </AliceContainer>
          </Flex>
          <Answers visible={chatState >= 2}>
            <Width>
              <AnswerContainer visible={chatState >= 2}>
                <Spacing size="10px" position="b">
                  <Bubble
                    pointer
                    bgColor="yellow"
                    isLoading={false}
                    copy={content.chatBot.answer1.copy}
                    as="a"
                    href={content.chatBot.answer1.url}
                  />
                </Spacing>
              </AnswerContainer>
              <AnswerContainer visible={chatState >= 2}>
                <Spacing size="10px" position="b">
                  <Link to="answer" spy={true} smooth={true} duration={500}>
                    <Bubble
                      pointer
                      onClick={() => { this.handleClick('cta2'); }}
                      hasNoArrow
                      isLoading={false}
                      copy={content.chatBot.answer2.copy}
                    />
                  </Link>
                </Spacing>
              </AnswerContainer>
              <AnswerContainer visible={chatState >= 2}>
                <Link to="answer" spy={true} smooth={true} duration={500}>
                  <Bubble
                    pointer
                    onClick={() => { this.handleClick('cta3'); }}
                    hasNoArrow
                    isLoading={false}
                    copy={content.chatBot.answer3.copy}
                  />
                </Link>
              </AnswerContainer>
            </Width>
            <div>
              <Avatar image={youImage} name="You" />
            </div>
          </Answers>
          <Spacing size="60px" position="t">
            <Flex id="answer">
              <LastAvatar ref={ref => { this.lastAvatarRef = ref; }}>
                <Avatar image={aliceImage} name="Ace & Tate" />
              </LastAvatar>
              <LastBubble ref={ref => { this.lastBubbleRef = ref; }}>
                <Bubble
                  pointer
                  as="a"
                  href={lastStateContent.url}
                  isLeft
                  size="l"
                  isLoading={false}
                  copy={lastStateContent.copy}
                />
              </LastBubble>
            </Flex>
          </Spacing>
        </Container>
      </Observer>
    )
  }
}