import React from 'react';

import Text from '../../common/Text';
import MaxWidth from '../../common/MaxWidth';
import Spacing from '../../common/Spacing';
import PageWrap from '../../common/PageWrap';
import Fade from '../../common/Fade';
import SectionBackground from '../../common/SectionBackground';

import theme from '../../../lib/theme';

import Circle from './Circle';

import content from '../../../content/vision.json';

export default function Vision() {
  return (
    <SectionBackground bgColor={theme.colors.white}>
      <PageWrap>
        <MaxWidth value={1045} center>
          <Fade threshold={0.3} duration={500}>
            <Spacing size={["55px", "40px", "40px"]} position="y">
              <Circle />
            </Spacing>
          </Fade>
          <Fade threshold={0.3} delay={200} duration={500}>
            <Spacing size={["130px", "100px", "75px"]} position="b">
              <Text
                as="p"
                align="center"
                font="serif"
                size="xxxl"
                copy={content.strapline}
              />
            </Spacing>
          </Fade>
        </MaxWidth>
      </PageWrap>
    </SectionBackground>
  )
}