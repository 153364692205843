import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import throttle from 'lodash/throttle';
import Observer from 'react-intersection-observer';
import isStaticRender from '../../../lib/isStaticRender';
import theme from '../../../lib/theme';
import getLang from '../../../lib/getLang';

import Text from '../Text';

import Container from './Container';
import PlayButton from './PlayButton';

import pauseIcon from '../../../assets/images/pause-icon.svg';

import hero from '../../../content/hero.json';

const easing = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)';
const duration = '800';

const sizeMap = {
  s: 70,
  l: 86
}

const PlayButtonContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  ${props => props.hero ? 'transform: translate(-50%, -50%) scale(0.8)' : 'transform: translate(-50%, -50%) scale(1)'};
  ${props => props.hero ? 'opacity: 0' : 'oapcity: 1'};
  transition: 0.5s 0.9s;
`;
const PosterContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transition: ${duration}ms ${easing};
  ${props => props.hero ? 'transform: translateY(-100%)' : 'transform: translateY(0)'};
`;

const StyledPoster = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  transform-origin: center;
  transition: ${duration}ms ${easing};
  ${props => props.hero ? 'transform: translateY(40%) scale(1.4)' : 'transform: translateY(0) scale(1)'};
  will-change: transform;
`;
const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.4s;
  opacity: 0;
  visibility: hidden;
  background-color: ${props => props.isHero ? theme.colors.yellow : 'transparent'};
`;
const StyledVideo = styled.video`
  position: absolute;
  top: ${props => props.isHero ? '50%' : 0};
  left: ${props => props.isHero ? '50%' : 0};
  width: 100%;
  height: 100%;
  ${props => props.isHero && 'max-width: 90%;'}
  ${props => props.isHero && 'max-height: 90%;'}
  ${props => props.isHero && 'transform: translate(-50%, -50%);'}
  transition: opacity 0.4s;
  opacity: 0;
  visibility: hidden;
  background-color: ${props => props.isHero ? theme.colors.yellow : 'transparent'};
`;
const BgVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  ${props => !props.isHero && 'max-width: 100%;'}

  @media screen and (max-width: 750px) {
    ${props => props.isHero && 'max-width: 100%;'}
  }
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: 10%;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  transition: 0.4s 0.6s;
  ${props => props.hero ? 'transform: translate(-50%, 50px)' : 'transform: translate(-50%, 0)'};
  ${props => props.hero ? 'opacity: 0' : 'oapcity: 1'};

  @media screen and (max-width: ${theme.breakpoints.s}) {
    width: 100%;
  }
`;

const PauseButton = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  width: ${props => sizeMap[props.size]}px;
  height: ${props => sizeMap[props.size]}px;

  &:hover {
    transform: translate(-50%, -50%) scale(1.1);
  }
`;

export default class Video extends PureComponent {
  constructor(props) {
    super(props);
    
    this.isPlaying = false;
    this.transitionDelay = isStaticRender ? 0 : 1000;

    this.state = {
      lang: 'en'
    }

    this.video = '';
    this.bgVideo = '';

    this.handleScroll = this.handleScroll.bind(this);
    this.pauseVideo = this.pauseVideo.bind(this);
    this.handleScroll = throttle(this.handleScroll.bind(this), 100);
    this.handleMouseMove = throttle(this.handleMouseMove.bind(this), 100);
  }

  componentDidMount() {
    this.setLang();
    const { isHero } = this.props;

    if (this.videoRef) {
      this.videoRef.addEventListener('ended', this.videoEnded, false);
    }
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('videoPlay', this.pauseVideo);
    if (this.containerRef) {
      this.containerRef.addEventListener('mousemove', this.handleMouseMove);
    }

    this.setVideo();

    if (isHero) {
      setTimeout(() => {
        this.handleLoading();
      }, this.transitionDelay);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src) {
      this.setVideo();
    }
  }

  componentWillUnmount() {
    if (this.videoRef) {
      this.videoRef.removeEventListener('ended', this.videoEnded);
    }
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('videoPlay', this.pauseVideo);
    if (this.containerRef) {
      this.containerRef.removeEventListener('mousemove', this.handleMouse);
    }
  }

  setLang() {
    const lang = getLang();
    this.setState({ lang });
  }

  setVideo() {
    const { bgVideo, bgVideoMobile, src, srcDe } = this.props;
    const isSmallScreen = window.innerWidth <= 750; 
    this.video = getLang() === 'de' ? srcDe : src;
    this.bgVideo = isSmallScreen ? bgVideoMobile : bgVideo;
    this.setState({ video: 'updated' })
  }

  handleMouseMove() {
    if (this.isPlaying) {
      this.timeout && clearTimeout(this.timeout);
      this.pauseRef.style.opacity = 1;
      this.pauseRef.style.visibility = 'visible';
      this.hidePause()
    }
  }

  hidePause() {
    this.timeout = setTimeout(() => {
      this.pauseRef.style.opacity = 0;
      this.pauseRef.style.visibility = 'hidden';
    }, 150)
  }

  handleScroll(e) {
    if (!this.props.isHero) return;

    const y = e.pageY || window.pageYOffset;
    const bounding = this.posterRef.getBoundingClientRect();
    const top = bounding.top;
    const height = bounding.height;
    const halfH = height / 2;
    const translateY = (y / (halfH - top) * 100);

    this.posterRef.style.transition = '0.1s 0s';
    this.posterRef.style.transform = `translate(0, ${translateY}px) scale(1.2)`;
  }

  handleLoading() {
    this.posterRef.style.transform = 'translateY(0) scale(1.2)';
    this.posterContainerRef.style.transform = 'translateY(0)';
    this.textRef.style.opacity = 1;
    this.textRef.style.transform = 'translate(-50%, 0)';
    if (this.playButtonContainerRef) {
      this.playButtonContainerRef.classList += ' draw';
      this.playButtonContainerRef.style.opacity = 1;
      this.playButtonContainerRef.style.transform = 'translate(-50%, -50%) scale(1)';
    }
  }

  handleVisibility(inView) {
    if (inView && !this.props.isHero) {
      this.playButtonContainerRef.classList += ' draw';
    }
  }

  videoEnded() {
    const { onStateChange } = this.props;

    this.isPlaying = false;
    this.videoRef.style.opacity = 0;
    this.videoContainerRef.style.opacity = 0;
    this.videoRef.style.visibility = 'hidden';
    this.videoContainerRef.style.visibility = 'hidden';

    if (this.playButtonContainerRef) {
      this.playButtonContainerRef.style.opacity = 1;
    }
    this.pauseRef.style.opacity = 0;
    this.pauseRef.style.visibility = 'hidden';

    if (onStateChange) onStateChange(this.isPlaying);
  }

  pauseVideo(e) {
    const { src } = this.props;
    if (e.src === src) return;

    this.isPlaying = false;
    this.videoRef.pause();
    if (this.playButtonContainerRef) {
      this.playButtonContainerRef.style.zIndex = 20;
      this.playButtonContainerRef.style.opacity = 1;
      this.playButtonContainerRef.style.transitionDelay = '0s';
    }
  }

  toggleState() {
    const { onStateChange, src, isHero } = this.props;
    this.videoRef.style.opacity = 1;
    this.videoContainerRef.style.opacity = 1;
    this.videoRef.style.visibility = 'visible';
    this.videoContainerRef.style.visibility = 'visible';

    if (this.isPlaying) {
      this.isPlaying = false;
      this.videoRef.pause();
      if (this.playButtonContainerRef) {
        this.playButtonContainerRef.style.zIndex = 20;
        this.playButtonContainerRef.style.opacity = 1;
        this.playButtonContainerRef.style.transitionDelay = '0s';
      }
    } else {
      this.isPlaying = true;
      this.videoRef.play();
      if (this.playButtonContainerRef) {
        this.playButtonContainerRef.style.zIndex = 0;
        this.playButtonContainerRef.style.opacity = 0;
        this.playButtonContainerRef.style.transitionDelay = '0s';
      }
      const evt = new CustomEvent('videoPlay');
      evt.src = src;
      evt.isHero = isHero;
      window.dispatchEvent(evt);
    }

    if (onStateChange) onStateChange(this.isPlaying);
  }

  render() {
    const { poster, ratio, src, srcDe, buttonSize, isHero, bgVideo } = this.props;
    const randomKey = Math.random();
    const srcNode = getLang() === 'de' ? srcDe : src;

    return (
      <Observer onChange={this.handleVisibility.bind(this)}>
        <div ref={ref => { this.containerRef = ref; }}>
        <Container isHero={isHero} ratio={ratio} onClick={this.toggleState.bind(this)}>
          <PosterContainer ref={ref => { this.posterContainerRef = ref; }} hero={isHero}>
            <StyledPoster ref={ref => { this.posterRef = ref; }} src={poster} hero={isHero}>
              {bgVideo && (
                <BgVideo
                  isHero={isHero}
                  src={this.bgVideo}
                  autoPlay
                  muted
                  playsInline
                  loop
                />
              )}
            </StyledPoster>
          </PosterContainer>
          {!isStaticRender && <PlayButtonContainer ref={ref => { this.playButtonContainerRef = ref; }} hero={isHero}>
            <PlayButton buttonSize={buttonSize} />
          </PlayButtonContainer>}
          {isHero && (
            <TextContainer hero={isHero} ref={ref => { this.textRef = ref; }}>
              <Text
                color="white"
                align="center"
                font="druk"
                size="hero"
                copy={hero.title}
              />
            </TextContainer>
          )}
          <VideoContainer isHero={isHero} ref={ref => { this.videoContainerRef = ref; }}>
            <StyledVideo
              key={randomKey}
              isHero={isHero}
              ref={ref => { this.videoRef = ref; }}
              src={isHero ? srcNode : this.video}
            />
          </VideoContainer>
          <PauseButton src={pauseIcon} alt="pause" size={buttonSize} ref={ref => { this.pauseRef = ref; }} />
        </Container>
        </div>
      </Observer>
    )
  }
}

Video.propTypes = {
  poster: PropTypes.node,
  src: PropTypes.node,
  ratio: PropTypes.string,
  buttonSize: PropTypes.string,
  isHero: PropTypes.bool,
  onClick: PropTypes.func
}

Video.defaultProps = {
  isHero: false,
  buttonSize: 's',
  onStateChange: null
}