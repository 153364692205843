import React, { PureComponent, Fragment } from "react";

import Video from "../../components/common/Video";
import FixedCTA from "../../components/common/FixedCTA";

import Vision from "../../components/section/Vision";
import Quotes from "../../components/section/Quotes";
import Principles from "../../components/section/Principles";
import Sustainability from "../../components/section/Sustainability";
import Join from "../../components/section/Join";
import Materials from "../../components/section/Materials";
import Instrument from "../../components/section/Instrument";
import Header from "../../components/common/Header";

const poster =
  "https://assets.aceandtate.com/campaigns/vision/images/hero-video-poster.jpg";
const bgVideo =
  "https://assets.aceandtate.com/campaigns/vision/videos/short.mp4";
const bgVideoMobile =
  "https://assets.aceandtate.com/campaigns/vision/videos/short_mobile.mp4";
const desktopVideo =
  "https://assets.aceandtate.com/campaigns/vision/videos/desktop.mp4";
const desktopSubVideo =
  "https://assets.aceandtate.com/campaigns/vision/videos/desktop_sub.mp4";
const tabletVideo =
  "https://assets.aceandtate.com/campaigns/vision/videos/tablet_sub.mp4";
const tabletSubVideo =
  "https://assets.aceandtate.com/campaigns/vision/videos/tablet_ger.mp4";
const mobileVideo =
  "https://assets.aceandtate.com/campaigns/vision/videos/mobile_sub.mp4";
const mobileSubVideo =
  "https://assets.aceandtate.com/campaigns/vision/videos/mobile_ger.mp4";

export default class Landing extends PureComponent {
  constructor() {
    super();

    this.videos = {
      desktop: {
        default: desktopVideo,
        sub: desktopSubVideo
      },
      tablet: {
        default: tabletVideo,
        sub: tabletSubVideo
      },
      mobile: {
        default: mobileVideo,
        sub: mobileSubVideo
      }
    };

    this.state = {
      sections: [],
      video: this.videos.desktop.default,
      videoSub: this.videos.desktop.sub
    };

    this.video = null;
  }

  componentDidMount() {
    this.setSections();
    this.setVideo();
  }

  setVideo() {
    const ww = window.innerWidth;

    if (ww <= 750) {
      this.setState({
        video: this.videos.mobile.default,
        videoSub: this.videos.mobile.sub
      });
    } else if (ww > 750 && ww <= 1024) {
      this.setState({
        video: this.videos.tablet.default,
        videoSub: this.videos.tablet.sub
      });
    } else {
      this.setState({
        video: this.videos.desktop.default,
        videoSub: this.videos.desktop.sub
      });
    }
  }

  setSections() {
    const sections = [
      {
        name: "hero",
        offsetTop: this.videoRef.offsetTop,
        offsetHeight: this.videoRef.offsetHeight
      },
      {
        name: "vision",
        offsetTop: this.visionRef.offsetTop,
        offsetHeight: this.visionRef.offsetHeight
      },
      {
        name: "quotes",
        offsetTop: this.quotesRef.offsetTop,
        offsetHeight: this.quotesRef.offsetHeight
      },
      {
        name: "materials",
        offsetTop: this.materialsRef.offsetTop,
        offsetHeight: this.materialsRef.offsetHeight
      },
      {
        name: "instrument",
        offsetTop: this.instrumentRef.offsetTop,
        offsetHeight: this.instrumentRef.offsetHeight
      },
      {
        name: "sustainability",
        offsetTop: this.sustainabilityRef.offsetTop,
        offsetHeight: this.sustainabilityRef.offsetHeight
      },
      {
        name: "principles",
        offsetTop: this.principlesRef.offsetTop,
        offsetHeight: this.principlesRef.offsetHeight
      },
      {
        name: "join",
        offsetTop: this.joinRef.offsetTop,
        offsetHeight: this.joinRef.offsetHeight
      }
    ];

    this.setState({ sections });
  }

  render() {
    const { sections, video, videoSub } = this.state;

    return (
      <Fragment>
        <Header />
        <FixedCTA sections={sections} />
        <div
          ref={ref => {
            this.videoRef = ref;
          }}
        >
          <Video
            bgVideo={bgVideo}
            bgVideoMobile={bgVideoMobile}
            poster={poster}
            src={video}
            srcDe={videoSub}
            ratio="53.15%"
            buttonSize="l"
            isHero
          />
        </div>

        <div
          ref={ref => {
            this.visionRef = ref;
          }}
        >
          <Vision />
        </div>

        <div
          ref={ref => {
            this.quotesRef = ref;
          }}
        >
          <Quotes />
        </div>

        <div
          ref={ref => {
            this.materialsRef = ref;
          }}
        >
          <Materials />
        </div>

        <div
          ref={ref => {
            this.instrumentRef = ref;
          }}
        >
          <Instrument />
        </div>

        <div
          ref={ref => {
            this.sustainabilityRef = ref;
          }}
        >
          <Sustainability />
        </div>

        <div
          ref={ref => {
            this.principlesRef = ref;
          }}
        >
          <Principles />
        </div>

        <div
          ref={ref => {
            this.joinRef = ref;
          }}
        >
          <Join />
        </div>
      </Fragment>
    );
  }
}
