import React from 'react';
import { hydrate, render } from 'react-dom';
import 'modern-normalize/modern-normalize.css';
import './index.css';
import Landing from './pages/Landing';
import * as serviceWorker from './serviceWorker';
import isStaticRender from './lib/isStaticRender';

require('intersection-observer');


const rootElement = document.getElementById("root");
if (isStaticRender) {
  render(<Landing />, rootElement);
} else {
  hydrate(<Landing />, rootElement);
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
