import React, { PureComponent } from 'react';
import styled, { keyframes } from 'styled-components/macro';

import PageWrap from '../../common/PageWrap';
import Text from '../../common/Text';
import Spacing from '../../common/Spacing';
import MaxWidth from '../../common/MaxWidth';
import Fade from '../../common/Fade';
import SectionBackground from '../../common/SectionBackground';

import theme from '../../../lib/theme';
import Tooltip from '../../common/Tooltip';

import image from '../../../assets/images/instrument-1.jpg';
import image2 from '../../../assets/images/instrument-2.jpg';
import imageMobile from '../../../assets/images/instrument-1-mobile.jpg';
import image2Mobile from '../../../assets/images/instrument-2-mobile.jpg';

import content from '../../../content/instrument.json';

const zindex = keyframes`
  0% { z-index: -1; }
  50% { z-index: -1; }
  100% { z-index: 1; }
`;
const Ratio = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
  height: 0;
  padding-bottom: 56%;
  overflow: hidden;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    padding-bottom: 120%;
  }
`;
const Img = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${image});
  background-size: cover;
  background-position: center;
  image-rendering: auto;

  transition: transform 0.5s ease-in-out;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    background-image: url(${imageMobile});
  }
`;
const Img2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(${image2});
  background-size: cover;
  background-position: center;
  image-rendering: auto;
  z-index: -1;
  transition: transform 0.5s ease-in-out;

  animation: ${zindex} 2s linear infinite;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    background-image: url(${image2Mobile});
  }
`;
const TooltipContainer = styled.div`
  opacity: 1;

  &:hover ~ ${Img},
  &:hover ~ ${Img2} {
    transform: scale(1.7);
  }
`;

const Container = styled.div`
  padding-top: 75px;
  padding-bottom: 130px;

  @media screen and (max-width: ${theme.breakpoints.m}) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media screen and (max-width: ${theme.breakpoints.s}) {
    padding-top: 40px;
    padding-bottom: 50px;
  }
`;

const isMobileUpdate = () => ({ isMobile: window.innerWidth <= 750 });

export default class Instrument extends PureComponent {
  constructor() {
    super();

    this.containersRef = [];

    this.handleHover = this.handleHover.bind(this);
    this.handleLeave = this.handleLeave.bind(this);

    this.state = {
      ...isMobileUpdate(),
    }
  }

  componentDidMount() {
    this.addEventListeners();
  }

  componentWillUnmount() {
    this.removeEventListeners();
  }

  addEventListeners() {
    this.containersRef.forEach((item, index) => {
      item.addEventListener('mouseenter', () => { this.handleHover(index) }, { passive: true });
      item.addEventListener('mouseleave', this.handleLeave, { passive: true });
      item.addEventListener('touchstart', () => { this.handleHover(index) }, { passive: true });
      item.addEventListener('touchend', this.handleLeave, { passive: true });
    });
    window.addEventListener('touchstart', this.handleLeave, { passive: true });
    window.addEventListener('resize', this.onResize, { passive: true });
  }

  removeEventListeners() {
    this.containersRef.forEach((item, index) => {
      item.removeEventListener('mouseenter', () => { this.handleHover(index) });
      item.removeEventListener('touchstart', () => { this.handleHover(index) });
      item.removeEventListener('mouseleave', this.handleLeave);
      item.removeEventListener('touchend', this.handleLeave);
    });
    window.removeEventListener('resize', this.onResize, { passive: true });
  }

  onResize = () => this.setState(isMobileUpdate);

  handleHover(index) {
    const [x, y] = this.state.isMobile ? ['xMobile', 'yMobile'] : ['x', 'y'];
    const transformOrigin = `calc(${content.tooltips[index][x]} + 13px) calc(${content.tooltips[index][y]} + 13px)`;
    this.imgRef.style.transformOrigin = transformOrigin;
    this.img2Ref.style.transformOrigin = transformOrigin;

    this.containersRef.forEach((item, i) => {
      if (index !== i) {
        item.firstChild.style.transition = '0.1s';
        item.firstChild.style.opacity = 0;
      }
    });
  }

  handleLeave(e) {
    this.containersRef.forEach(item => {
      if (e.target === item) return;
      item.firstChild.style.transition = '0.1s';
      item.firstChild.style.transitionDelay = '0.4s';
      item.firstChild.style.opacity = 1;
    });
  }

  render() {
    return (
      <SectionBackground bgColor={theme.colors.yellow}>
        <Container>
          <PageWrap>
            <Fade threshold={0.3} duration={500}>
              <Text
                size="xxxxl"
                weight="500"
                align="center"
                copy={content.title}
              />
            </Fade>
            <Fade threshold={0.3} duration={500} delay={100}>
              <Spacing size="40px" position="t">
                <MaxWidth value={700} center>
                  <Text
                    size="xs"
                    font="serif"
                    align="center"
                    copy={content.subtitle}
                  />
                </MaxWidth>
              </Spacing>
            </Fade>
            <Fade duration={550} threshold={0.1}>
              <Spacing size={["95px", "60px", "45px"]} position="t">
                <Ratio>
                  {content.tooltips.map((item, index) => {
                    const x = this.state.isMobile ? item.xMobile : item.x;
                    const y = this.state.isMobile ? item.yMobile : item.y;

                    return (
                      <TooltipContainer
                        ref={ref => { this.containersRef[index] = ref; }}
                        key={index}
                      >
                        <Tooltip copy={item.copy} x={x} y={y} position={item.position} />
                      </TooltipContainer>
                    )
                  })}
                  <Img ref={ref => { this.imgRef = ref; }} />
                  <Img2 ref={ref => { this.img2Ref = ref; }} />
                </Ratio>
              </Spacing>
            </Fade>
          </PageWrap>
        </Container>
      </SectionBackground>
    )
  }
}