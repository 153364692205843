import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import theme from '../../../../lib/theme';

const sizeMap = {
  s: 70,
  l: 86
}

export default function PlayButton({ buttonSize }) {
  const size = sizeMap[buttonSize];
  const Container = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-${size / 2}px, -${size / 2}px);
    width: ${size}px;
    height: ${size}px;

    svg {
      transition: 0.3s;
    }

    &:hover svg {
      transform: scale(1.1);
    }

    @media screen and (max-width: ${theme.breakpoints.m}) {
      width: ${sizeMap.s}px;
      height: ${sizeMap.s}px;
      transform: translate(-${sizeMap.s / 2}px, -${sizeMap.s / 2}px);
    }

    #circle,
    #triangle {
      transition: 1s 1s cubic-bezier(0.770, 0.000, 0.175, 1.000);
    }

    #circle {
      fill:#FFFFFF;
      stroke:#373737;
      stroke-width:2.3;

      stroke-dasharray: 265;
      stroke-dashoffset: 265;
    }

    #triangle {
      fill:#E53416;
      stroke:#262626;
      stroke-width:3;

      stroke-dasharray: 65;
      stroke-dashoffset: 65;
    }

    .draw & #circle,
    .draw & #triangle {
      stroke-dashoffset: 0;
    }
  `;

  return (
    <Container>
      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 86 86">
        <circle id="circle" cx="43" cy="43" r="41.8" />
        <path id="triangle" d="M37.5,31.3l12.9,10c0.8,0.6,0.8,1.7,0,2.3L37.3,53.7c-1,0.7-2.3,0-2.3-1.2l0.2-20.1 C35.2,31.2,36.5,30.6,37.5,31.3z"/>
      </svg>
    </Container>
  )
}

PlayButton.propTypes = {
  buttonSize: PropTypes.oneOf(['s', 'l'])
}

PlayButton.defaultProps = {
  buttonSize: 's'
}