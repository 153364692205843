import React from 'react';
import styled from 'styled-components/macro';

import theme from '../../../lib/theme';

import Spacing from '../../common/Spacing';
import Text from '../../common/Text';
import MaxWidth from '../../common/MaxWidth';
import PageWrap from '../../common/PageWrap';
import Fade from '../../common/Fade';
import SectionBackground from '../../common/SectionBackground';

import MovingQuotes from './MovingQuotes';

import content from '../../../content/quotes.json';

export default function Quotes() {
  const Container = styled.div`
    color: ${theme.colors.white};
  `;

  return (
    <SectionBackground bgColor={theme.colors.red}>
      <Container>
        <PageWrap>
          <Fade threshold={0.3} duration={500}>
            <Spacing
              type="padding"
              size="70px"
              position="t"
            >
              <Text
                align="center"
                size="xxxxl"
                color="pink"
                weight={500}
                copy={content.title}
                lineHeight={1}
              />
            </Spacing>
            <MaxWidth value={735} center>
              <Spacing size={["20px", "20px", "40px"]} position="t">
                <Text
                  font="serif"
                  as="p"
                  color="pink"
                  align="center"
                  size="xs"
                  copy={content.subtitle}
                />
              </Spacing>
            </MaxWidth>
          </Fade>
        </PageWrap>
        <Spacing size={["80px", "80px", "30px"]} position="t">
          <MovingQuotes quotes={content.quotes} />
        </Spacing>
      </Container>
    </SectionBackground>
  )
}