import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components/macro';

import Text from '../Text';

import theme from '../../../lib/theme';

const pulse = keyframes`
  0% {
    transform: scale(1) rotate(0.001deg);
  }
  75% {
    transform: scale(1) rotate(0.001deg);
  }
  100% {
    transform: scale(0.7) rotate(0.001deg);
  }
`;
const Container = styled.div`
  position: fixed;
  z-index: 10;
  top: ${props => props.y};
  left: ${props => props.x};

  // @media screen and (max-width: ${theme.breakpoints.m}) {
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  // }
`;
const Copy = styled.div`
  width: 290px;
  position: absolute;
  z-index: 10;
  top: -5px;
  left: ${props => props.isRight ? '35px' : 'auto'};
  right: ${props => !props.isRight ? '35px' : 'auto'};
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.black};
  font-size: ${theme.fontSizes.xs[0]};
  border-radius: 4px;
  padding: 18px 14px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);

  transform: scale(0.8);
  opacity: 0;
  visibility: hidden; 
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transform-origin: ${props => props.isRight ? 'left top' : 'right top'};

  @media screen and (min-width: 769px) {
    &:hover {
      opacity: 1;
      visibility: visible; 
      transform: scale(1);
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::after {
    left: ${props => props.isRight ? '-10px' : 'auto'};
    right: ${props => !props.isRight ? '-10px' : 'auto'};
    top: 12px;
    border: 5px solid transparent;
    border-right-color: ${props => props.isRight ? theme.colors.white : 'transparent'};
    border-left-color: ${props => !props.isRight ? theme.colors.white : 'transparent'};
  }

  &::before {
    left: ${props => props.isRight ? '-14px' : 'auto'};
    right: ${props => !props.isRight ? '-14px' : 'auto'};
    top: 10px;
    border: 7px solid transparent;
    border-right-color: ${props => props.isRight ? theme.colors.black : 'transparent'};
    border-left-color: ${props => !props.isRight ? theme.colors.black : 'transparent'};
  }

  @media screen and (max-width: ${theme.breakpoints.m}) {
    position: fixed;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    top: calc(${props => props.y} + 30px);
    right: auto;

    opacity: ${props => props.isOpen ? 1 : 0};
    visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
    transform: ${props => props.isOpen ? 'translateX(-50%) scale(1)' : 'translateX(-50%)'};

    &::after {
      // border-right-color: transparent;
      // border-left-color: transparent;
      // left: calc(${props => props.x} * 1.05);
      // right: auto;
      // top: -10px;
      // border-bottom-color: ${theme.colors.white};
      opacity: 0;
    }

    &::before {
      // border-right-color: transparent;
      // border-left-color: transparent;
      // left: calc(${props => props.x} * 1.05 - 2px);
      // right: auto;
      // top: -14px;
      // border-bottom-color: ${theme.colors.black};
      opacity: 0;
    }
  }
`;
const Dot = styled.div`
  position: relative;
  cursor: pointer;
  padding: 0;
  transition: 0.3s cubic-bezier(0.175, 0.885, 0.320, 1.275);
  animation: ${pulse} 1s alternate infinite;
  animation-delay: ${props => props.delay}s;

  &::after {
    content: "";
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 2px solid ${theme.colors.black};
    background-color: ${theme.colors.white};
  }

  &:hover {
    transform: scale(0.9) rotate(0.001deg);
    animation: none;
  }

  @media screen and (min-width: 769px) {
    &:hover ~ ${Copy} {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }

  // @media screen and (max-width: ${theme.breakpoints.m}) {
  //   position: absolute;
  //   left: ${props => props.x};
  //   top: calc(${props => props.y} - 25px);
  //   padding: 20px;
  // }
`;

export default class Tooltip extends PureComponent {
  constructor() {
    super();

    this.state = {
      isOpen: false
    }

    this.touchStart = this.touchStart.bind(this);
    this.touchOut = this.touchOut.bind(this);
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.touchOut, { passive: true });
    this.dotRef.addEventListener('touchstart', this.touchStart, { passive: true });
  }

  componentWillUnmount() {
    this.dotRef.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchstart', this.touchOut);
  }

  touchStart() {
    this.setState({ isOpen: true });
  }

  touchOut(e) {
    if (e.target === this.dotRef) return;
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    const { isOpen } = this.state;
    const { copy, x, y, position } = this.props;
    const isRight = position === 'right';
    const delay = Math.random() / 3;

    return (
      <Container x={x} y={y} isRight={isRight}>
        <Dot ref={ref => { this.dotRef = ref; }} x={x} y={y} isRight={isRight} delay={delay} />
        <Copy isOpen={isOpen} x={x} y={y} isRight={isRight} >
          <Text as="div" size="xxxs" copy={copy} />
        </Copy>
      </Container>
    );
  }
}

Tooltip.propTypes = {
  copy: PropTypes.object.isRequired,
  x: PropTypes.string,
  y: PropTypes.string,
  position: PropTypes.oneOf(['left', 'right'])
}

Tooltip.defaultProps = {
  position: 'right'
}