import React from 'react';

import theme from '../../../lib/theme';
import Spacing from '../../common/Spacing';
import Text from '../../common/Text';
import ChatBot from './ChatBot';
import PageWrap from '../../common/PageWrap';
import Fade from '../../common/Fade';
import SectionBackground from '../../common/SectionBackground';

import content from '../../../content/join.json';

export default function Join() {
  return (
    <SectionBackground bgColor={theme.colors.blue}>
      <div id="chat">
        <PageWrap>
          <Fade threshold={0.3} duration={500}>
            <Spacing size="50px" position="y" type="padding">
              <Text
                size="xxxxl"
                align="center"
                weight="500"
                copy={content.title}
              />

              <Spacing size={["100px", "70px", "40px"]} position="t">
                <ChatBot />
              </Spacing>
            </Spacing>
          </Fade>
        </PageWrap>
      </div>
    </SectionBackground>
  )
}