import React, { PureComponent } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-scroll'

import PageWrap from '../PageWrap';
import Avatar from '../Avatar';
import Bubble from '../Bubble';

import theme from '../../../lib/theme';
import Raf from '../../../lib/Raf';

import image from '../../../assets/images/alice.png';

import sectionMessages from '../../../content/fixedCTA.json';

const Container = styled.div`
  position: fixed;
  z-index: 1000;
  bottom: 35px;
  right: 0;
`;

const Flex = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const BubbleContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 70px;
  transform: translateY(-50%);
  width: 323px;
  transition: 0.2s;
  opacity: 0;
  transform-origin: right center;

  @media screen and (max-width: ${theme.breakpoints.s}) {
    right: 40px;
    width: 280px;
  }
`;

const AvatarContainer = styled.div`
  transition: 0.2s;

  opacity: 0;
`;

export default class FixedCTA extends PureComponent {
  constructor() {
    super();

    this.currentSection = 'hero';

    this.bubbleContainerRef = [];

    this.offset = 70;
    this.RAF = Raf;
    this.RAFID = null;

    this.animateLoop = this.animateLoop.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });

    this.RAFID = this.RAF.subscribe(this.animateLoop);
    this.RAF.start();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);

    this.RAF.unsubscribe(this.RAFID);
  }

  animateLoop(e) {
    const currentSection = this.currentSection
    const { sections } = this.props;
    const y = window.pageYOffset;

    sections.forEach(section => {
      const name = section.name;
      const top = (section.offsetTop - window.innerHeight) + this.offset;
      const topPlusHeight = (top + section.offsetHeight);

      if (top <= y && y <= topPlusHeight && currentSection !== name) {
        this.currentSection = name;

        if (sectionMessages[section.name].hidden) {
          this.avatarContainerRef.style.transform = 'translateX(15px)';
          this.avatarContainerRef.style.visibility = 'hidden';
          this.avatarContainerRef.style.opacity = 0;
          this.avatarContainerRef.style.transitionDelay = '0s';
        } else {
          this.avatarContainerRef.style.opacity = 1;
          this.avatarContainerRef.style.transform = 'translateX(0)';
          this.avatarContainerRef.style.visibility = 'visible';
          this.avatarContainerRef.style.transitionDelay = '0s';
        }
      }

      if (currentSection === name && !sectionMessages[name].hidden) {
        this.bubbleContainerRef[name].style.opacity = 1;
        this.bubbleContainerRef[name].style.visibility = 'visible';
        this.bubbleContainerRef[name].style.transform = 'translate(0, -50%) scale(1)';
        this.bubbleContainerRef[name].style.transitionDelay = '0.1s';
      } else {
        this.bubbleContainerRef[name].style.opacity = 0;
        this.bubbleContainerRef[name].style.visibility = 'hidden';
        this.bubbleContainerRef[name].style.transform = 'translate(0, -50%) scale(0.8)';
        this.bubbleContainerRef[name].style.transitionDelay = '0s';
      }
    });
  }

  render() {
    const sections = Object.keys(sectionMessages);

    return (
      <Container ref={ref => { this.containerRef = ref; }}>
        <PageWrap noOverflow>
          <Flex>
            {sections.map((item, index) => {
              const section = sectionMessages[item];
              const message = section.copy;
              const url = section.url;

              return (
                <BubbleContainer key={index} ref={ref => { this.bubbleContainerRef[item] = ref; }}>
                  <Link to="chat" spy={true} smooth={true} duration={500}>
                    <Bubble
                      pointer
                      align="center"
                      size="xxs"
                      isLoading={false}
                      copy={message}
                      as={url ? "a" : "div"}
                      href={url || null}
                    />
                  </Link>
                </BubbleContainer>
              )
            })}
            <AvatarContainer ref={ref => { this.avatarContainerRef = ref; }}>
              <Avatar image={image} />
            </AvatarContainer>
          </Flex>
        </PageWrap>
      </Container>
    )
  }
}