import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import theme from '../../../lib/theme';

const positionMap = {
  't': '-top',
  'b': '-bottom',
  'r': '-right',
  'l': '-left',
  'x': '',
  'y': '',
  'all': ''
}

export default function Spacing({ children, type, size, position }) {
  const isArray = size instanceof Array;
  const sizes = {
    default: isArray ? size[0] : size,
    m: isArray ? size[1] : size,
    s: isArray ? size[2] : size,
  }
  const isBothSides = position === 'x' || position === 'y' ? true : false;
  const sideSize = position === 'x' ? `0 ${sizes.default} 0 ${sizes.default}` : `${sizes.default} 0 ${sizes.default} 0`;
  const sideSizeM = position === 'x' ? `0 ${sizes.m} 0 ${sizes.m}` : `${sizes.m} 0 ${sizes.m} 0`;
  const sideSizeS = position === 'x' ? `0 ${sizes.s} 0 ${sizes.s}` : `${sizes.s} 0 ${sizes.s} 0`;
  const StyledSpacing = styled.div`
    ${type}${positionMap[position]}: ${isBothSides ? sideSize : sizes.default };

    @media screen and (max-width: ${theme.breakpoints.m}) {
      ${type}${positionMap[position]}: ${isBothSides ? sideSizeM : sizes.m };
    }

    @media screen and (max-width: ${theme.breakpoints.s}) {
      ${type}${positionMap[position]}: ${isBothSides ? sideSizeS : sizes.s };
    }
  `;

  return (
    <StyledSpacing>{children}</StyledSpacing>
  )
}

Spacing.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  position: PropTypes.oneOf(['t', 'l', 'r', 'b', 'x', 'y', 'all']),
  type: PropTypes.oneOf(['padding', 'margin'])
}

Spacing.defaultProps = {
  position: 'all',
  type: 'margin'
}