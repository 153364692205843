import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

export default function MaxWidth({ children, value, center, ...props }) {
  const StyledMaxWidth = styled.div`
    max-width: ${value}px;
    margin: 0 ${center ? 'auto' : 0} 0 ${center ? 'auto' : 0};
  `;

  return (
    <StyledMaxWidth {...props}>{children}</StyledMaxWidth>
  )
}

MaxWidth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  value: PropTypes.number.isRequired
}