import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

import theme from '../../../lib/theme';
import getLang from '../../../lib/getLang';

const colors = Object.keys(theme.colors);
const fontSizes = Object.keys(theme.fontSizes);
const fonts = Object.keys(theme.fonts);

export default class Text extends PureComponent {
  constructor() {
    super();

    this.state = {
      lang: 'en'
    }
  }

  componentDidMount() {
    this.setLang();
  }

  setLang() {
    const lang = getLang();
    this.setState({ lang });
  }

  render() {
    const {
      copy,
      color,
      size,
      align,
      font,
      weight,
      lineHeight,
      isUnderlined,
      ...props
    } = this.props;
    const { lang } = this.state;

    const StyledText = styled.h2`
      position: relative;
      overflow: ${isUnderlined ? 'hidden' : 'initial'};
      display: ${isUnderlined ? 'inline-block' : 'inherit'};
      color: ${theme.colors[color]};
      line-height: ${lineHeight};
      font-family: ${theme.fonts[font]};
      font-size: ${theme.fontSizes[size][0]};
      font-weight: ${weight};
      text-align: ${align};
      margin: 0;

      &::after {
        content: "";
        display: ${isUnderlined ? 'block' : 'none'};
        width: 100%;
        height: 2px;
        background-color: ${theme.colors.yellow};
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &::before {
        content: "";
        display: ${isUnderlined ? 'block' : 'none'};
        width: 100%;
        height: 2px;
        background-color: ${theme.colors.yellow};
        transform: translateX(-500%);
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &:hover {
        &::after {
          transform: translateX(100%);
          transition: transform 0.2s ease-in-out;
        }

        &::before {
          transform: translateX(0);
          transition: transform 0.4s ease-in-out;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.m}) {
        font-size: ${theme.fontSizes[size][1]};
      }

      @media screen and (max-width: ${theme.breakpoints.s}) {
        font-size: ${theme.fontSizes[size][2]};
      }
    `;

  return (
    <StyledText
      {...props}
      dangerouslySetInnerHTML={{ __html: copy[lang] }}
    />
  )

  }
}

Text.propTypes = {
  copy: PropTypes.object,
  color: PropTypes.oneOf(colors),
  size: PropTypes.oneOf(fontSizes),
  font: PropTypes.oneOf(fonts),
  align: PropTypes.string,
  weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isUnderlined: PropTypes.bool,
  lineHeight: PropTypes.number
}

Text.defaultProps = {
  color: 'black',
  size: 's',
  font: 'sans',
  align: 'left',
  weight: 'normal',
  isUnderlined: false,
  lineHeight: 1.2
}